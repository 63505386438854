import { makeActionCreator, encodeParams, formatDate } from "../../utils";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import { serializeFiltersToQueryObject } from "./filtersActions";
import { stringify as stringifyQueryString } from "querystring";
import { showPromptModal } from "../../actions/modalsActions";
import moment from "moment";

export const IS_LOADING_CHANGED = "ORDERS_LIST.RESULTS.IS_LOADING_CHANGED";
export const DATA_LOADED = "ORDERS_LIST.RESULTS.DATA_LOADED";
export const SELECTED_CHANGED = "ORDERS_LIST.RESULTS.SELECTED_CHANGED";
export const SELECT_DESELECT_ALL = "ORDERS_LIST.RESULTS.SELECT_DESELECT_ALL";
export const CALCULATABLE_FIELD_IS_LOADING_CHANGED = "ORDERS_LIST.RESULTS.CALCULATABLE_FIELD_IS_LOADING_CHANGED";
export const CALCULATABLE_FIELD_DATA_CHANGED = "ORDERS_LIST.RESULTS.CALCULATABLE_FIELD_DATA_CHANGED";
export const CALCULATABLE_TOTALS_IS_LOADING_CHANGED = "ORDERS_LIST.RESULTS.CALCULATABLE_TOTALS_IS_LOADING_CHANGED";
export const CALCULATABLE_TOTALS_DATA_CHANGED = "ORDERS_LIST.RESULTS.CALCULATABLE_TOTALS_DATA_CHANGED";
export const CALCULATABLE_TOTALS_CLEAR = "ORDERS_LIST.RESULTS.CALCULATABLE_TOTALS_CLEAR";

export const isLoadingChanged = makeActionCreator(IS_LOADING_CHANGED, "payload");
export const dataLoaded = makeActionCreator(DATA_LOADED, "enabledColumns", "totalRows", "payload");
export const selectedChanged = makeActionCreator(SELECTED_CHANGED, "rowId", "payload");
export const selectDeselectAll = makeActionCreator(SELECT_DESELECT_ALL, "payload");
export const calculatableFieldIsLoadingChanged = makeActionCreator(
    CALCULATABLE_FIELD_IS_LOADING_CHANGED,
    "rowId",
    "fieldKey",
    "payload"
);
export const calculatableFieldDataChanged = makeActionCreator(
    CALCULATABLE_FIELD_DATA_CHANGED,
    "rowId",
    "fieldKey",
    "payload"
);

export const calculatableTotalsIsLoadingChanged = makeActionCreator(
    CALCULATABLE_TOTALS_IS_LOADING_CHANGED,
    "fieldKey",
    "payload"
);

export const calculatableTotalsDataChanged = makeActionCreator(CALCULATABLE_TOTALS_DATA_CHANGED, "fieldKey", "payload");

export const calculatableTotalsClear = makeActionCreator(CALCULATABLE_TOTALS_CLEAR, "fieldKey", "payload");

export let requestCache = {}; // this global variable holds the request cache for prefetching and faster response times

export function loadRows() {
    return async (dispatch, getState) => {
        const requestUrl = `/api/orders-list?` + stringifyQueryString(dispatch(serializeFiltersToQueryObject(true)));
        try {
            const enabledColumns = getState().ordersList.filters.customizerEnabledColumns; // copy the enabled columns at the time of the request
            if (!requestCache[requestUrl]) {
                requestCache[requestUrl] = (async () => {
                    const resp = await dispatch(apiFetch(requestUrl, {}));
                    const data = await resp.json();
                    if (!resp.ok) {
                        throw new Error(data.message);
                    }
                    return data;
                })();
            }
            const { rowsPerPage, page } = getState().ordersList.filters;

            // we can prefetch the next page
            dispatch(prefetchPage(page + 1));

            const data = await requestCache[requestUrl];

            dispatch(
                dataLoaded(
                    enabledColumns,
                    data.totalRows,
                    data.rows.map(r => ({
                        ...r,
                        selected: false,
                        totalHours: { data: null, isLoading: false },
                        totalChecked: { data: null, isLoading: false },
                        totalReworked: { data: null, isLoading: false },
                        nok: { data: null, isLoading: false }
                    }))
                )
            );
            dispatch(loadCalculatableTotals());
        } catch (e) {
            delete requestCache[requestUrl];
            console.error(e);
            toast("Failed to load orders: " + e.message, { type: "error" });
        }
    };
}

export function prefetchPage(page) {
    return async (dispatch, getState) => {
        const requestUrl =
            `/api/orders-list?` + stringifyQueryString({ ...dispatch(serializeFiltersToQueryObject(true)), page });
        if (!requestCache[requestUrl]) {
            requestCache[requestUrl] = (async () => {
                const resp = await dispatch(apiFetch(requestUrl, {}));
                const data = await resp.json();
                if (!resp.ok) {
                    throw new Error(data.message);
                }
                return data;
            })().catch(e => {
                console.error("failed to prefetch next page:", e);
                delete requestCache[requestUrl];
            });
        }
    };
}

export function loadCalculatableTotals() {
    return async (dispatch, getState) => {
        const selectedRows = getState().ordersList.results.rows.filter(r => r.selected);
        if (selectedRows.length === 0) {
            dispatch(calculatableTotalsClear());
            return;
        }
        const joinedOrderIds = selectedRows.map(r => r._id).join(",");
        const dateStart = getState().ordersList.filters.dateRange.start;
        const dateEnd = getState().ordersList.filters.dateRange.end;
        const fieldEnpoints = {
            totalChecked: encodeParams`/api/orders-list/order-total-checked/${joinedOrderIds}?dateStart=${dateStart}&dateEnd=${dateEnd}`,
            nok: encodeParams`/api/orders-list/order-nok/${joinedOrderIds}?dateStart=${dateStart}&dateEnd=${dateEnd}`,
            totalHours: encodeParams`/api/orders-list/order-total-hours/${joinedOrderIds}?dateStart=${dateStart}&dateEnd=${dateEnd}`,
            totalReworked: encodeParams`/api/orders-list/order-total-reworked/${joinedOrderIds}?dateStart=${dateStart}&dateEnd=${dateEnd}`
        };

        const enabledColumns = getState().ordersList.filters.customizerEnabledColumns;
        for (const enabledColumnKey of enabledColumns) {
            if (enabledColumnKey in fieldEnpoints) {
                try {
                    dispatch(calculatableTotalsIsLoadingChanged(enabledColumnKey, true));
                    const resp = await dispatch(apiFetch(fieldEnpoints[enabledColumnKey]));
                    const data = await resp.json();
                    if (!resp.ok) {
                        throw new Error(data.message);
                    }
                    dispatch(calculatableTotalsDataChanged(enabledColumnKey, data));
                } catch (e) {
                    dispatch(calculatableTotalsIsLoadingChanged(enabledColumnKey, false));
                    console.error(e);
                    toast("Failed to load calculatable total field: " + e.message, { type: "error" });
                }
            }
        }
    };
}

export function loadTotalHoursForOrder(orderId) {
    return async (dispatch, getState) => {
        try {
            const dateStart = getState().ordersList.filters.dateRange.start;
            const dateEnd = getState().ordersList.filters.dateRange.end;
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalHours", true));
            const resp = await dispatch(
                apiFetch(
                    encodeParams`/api/orders-list/order-total-hours/${orderId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(calculatableFieldDataChanged(orderId, "totalHours", data));
        } catch (e) {
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalHours", false));
            console.error(e);
            toast("Failed to load total hours: " + e.message, { type: "error" });
        }
    };
}

export function loadTotalCheckedForOrder(orderId) {
    return async (dispatch, getState) => {
        try {
            const dateStart = getState().ordersList.filters.dateRange.start;
            const dateEnd = getState().ordersList.filters.dateRange.end;
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalChecked", true));
            const resp = await dispatch(
                apiFetch(
                    encodeParams`/api/orders-list/order-total-checked/${orderId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(calculatableFieldDataChanged(orderId, "totalChecked", data));
        } catch (e) {
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalChecked", false));
            console.error(e);
            toast("Failed to load total checked: " + e.message, { type: "error" });
        }
    };
}

export function loadReworkedForOrder(orderId) {
    return async (dispatch, getState) => {
        try {
            const dateStart = getState().ordersList.filters.dateRange.start;
            const dateEnd = getState().ordersList.filters.dateRange.end;
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalReworked", true));
            const resp = await dispatch(
                apiFetch(
                    encodeParams`/api/orders-list/order-total-reworked/${orderId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(calculatableFieldDataChanged(orderId, "totalReworked", data));
        } catch (e) {
            dispatch(calculatableFieldIsLoadingChanged(orderId, "totalReworked", false));
            console.error(e);
            toast("Failed to load total reworked: " + e.message, { type: "error" });
        }
    };
}

export function loadNokForOrder(orderId) {
    return async (dispatch, getState) => {
        try {
            const dateStart = getState().ordersList.filters.dateRange.start;
            const dateEnd = getState().ordersList.filters.dateRange.end;
            dispatch(calculatableFieldIsLoadingChanged(orderId, "nok", true));
            const resp = await dispatch(
                apiFetch(encodeParams`/api/orders-list/order-nok/${orderId}?dateStart=${dateStart}&dateEnd=${dateEnd}`)
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(calculatableFieldDataChanged(orderId, "nok", data));
        } catch (e) {
            dispatch(calculatableFieldIsLoadingChanged(orderId, "nok", false));
            console.error(e);
            toast("Failed to load nok: " + e.message, { type: "error" });
        }
    };
}

export function finishOrder(orderId) {
    return async (dispatch, getState) => {
        try {

            const finishDate = await dispatch(
                showPromptModal({
                    title: "Do you reallly want to finish order?",
                    requireContent: true,
                    fieldType: "date",
                    fieldLabel: "Finish date",
                    message: "",
                    defaultValue: moment()
                })
            );
            if (!finishDate) {

                return;
            }
            dispatch(isLoadingChanged(true));
            const latestVersionResp = await dispatch(apiFetch(encodeParams`/api/orders/${orderId}`));
            const latestVersionData = await latestVersionResp.json();
            if (!latestVersionResp.ok) {
                throw new Error(latestVersionData.message);
            }
            const newVersionResp = await dispatch(
                apiFetch(encodeParams`/api/orders/${orderId}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        ...latestVersionData,
                        versionMetadata: {
                            message: "Order finished " + formatDate(finishDate)
                        },
                        finished: true,
                        finishDate: finishDate.toDate(),
                        scenarioId: latestVersionData.scenario.id,
                        calendarId: latestVersionData.calendar.id,
                        orderId: latestVersionData._id
                    })
                })
            );
            if (!newVersionResp.ok) {
                throw new Error(newVersionResp.message);
            }
            toast("Order finished successfully!", { type: "success" });
            requestCache = {};
            dispatch(loadRows());
        } catch (e) {
            console.error(e);
            toast("Failed to finish order: " + e.message, { type: "error" });
        }
    };
}

export function finishSelectedOrders() {
    return async (dispatch, getState) => {
        const orderIds = getState()
            .ordersList.results.rows.filter(row => !row.finished && row.selected)
            .map(o => o._id);
        const finishDate = await dispatch(
            showPromptModal({
                title: `Do you reallly want to finish ${orderIds.length} orders?`,
                requireContent: true,
                fieldType: "date",
                fieldLabel: "Finish date",
                message: "",
                defaultValue: moment()
            })
        );
        if (!finishDate) {
            return;
        }
        try {
            dispatch(isLoadingChanged(true));
            const resp = await dispatch(
                apiFetch(`/api/orders/finish-many`, { method: "POST", body: JSON.stringify({ finishDate, orderIds }) })
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            toast(`${orderIds.length} orders finished successfully!`, { type: "success" });
            requestCache = {};
            dispatch(loadRows());
        } catch (e) {
            console.error(e);
            toast("Failed to finish orders: " + e.message, { type: "error" });
        }
    };
}
