import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import StyledIcon from "../../../../components/common/StyledIcon";
import Bottom from "../Bottom";
import {Hr, MainValueContainerHeader} from "../common";
import {
    MainContainer,
    MainValueContainerValue,
    MainValueContainerSeparator,
    MainValueContainer,
    ContainerInside,
    IconSpan,
    IconContainer,
    Container2,
    Container
} from "./common";
import {Tooltip, OverlayTrigger} from "react-bootstrap";

export default function PrimaryContainer(props) {

    const [range, setRange] = useState("");

    useEffect(() => {
        setRange(props.defaultRange)
    }, [props])
    const dictionary = {
        '1': {
            year: "This label displays data for the current year, based on all the orders you are assigned to. These may not be orders from your entire company.",
            total: "This label displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
        },
        '2': {
            week: "This label displays data for the last seven days, excluding today, based on all the orders you are assigned to. These may not be orders from your entire company.",
            month: "This label displays data for the current month and previous months, based on all the orders you are assigned to. These may not be orders from your entire company.",
            year: "This label displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
        },
        '3': {
            week: "This label displays data for the last seven days, excluding today, based on all the orders you are assigned to. These may not be orders from your entire company.",
            month: "This label displays data for the current month and previous months, based on all the orders you are assigned to. These may not be orders from your entire company.",
            year: "This label displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
        },
        '4': {
            week: "This label displays data for the last seven days, excluding today, based on all the orders you are assigned to. These may not be orders from your entire company.",
            month: "This label displays data for the current month and previous months, based on all the orders you are assigned to. These may not be orders from your entire company.",
            year: "This label displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
        },
        '5': {
            week: "This label displays data for the last seven days, excluding today, based on all the orders you are assigned to. These may not be orders from your entire company.",
            month: "This label displays data for the current month and previous months, based on all the orders you are assigned to. These may not be orders from your entire company.",
            year: "This label displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
        }
    }
    return (
        <MainContainer>
            <Container>
                <OverlayTrigger overlay={(
                    <Tooltip id="tooltip">{dictionary[props.mode][range]}</Tooltip>)} placement="top" delayShow={1500}>
                        <span>
                            <ContainerInside onClick={() => props.clickFunction(props.newValue)}
                                             clickFunction={props.clickFunction}>
                                <Container2>
                                    <IconContainer mode={props.mode}>
                                        <IconSpan>
                                            <StyledIcon
                                                clickable
                                                glyph={props.icon}
                                                color="#fff"
                                                size="small"
                                            />
                                        </IconSpan>
                                    </IconContainer>
                                    <MainValueContainer>
                                        <MainValueContainerHeader>{props.title}</MainValueContainerHeader>
                                        {props.values && props.values.length === 1 && (<MainValueContainerValue>
                                            {props.values[0]}
                                        </MainValueContainerValue>)}
                                        {props.mode === "1" && ( // orders active / closed
                                            <>
                                                <MainValueContainerValue>
                                                    {props.values.active[range]}
                                                    <MainValueContainerHeader> active </MainValueContainerHeader>
                                                    <MainValueContainerSeparator> | </MainValueContainerSeparator>
                                                    {props.values.closed[range]}
                                                    <MainValueContainerHeader> closed </MainValueContainerHeader>
                                                </MainValueContainerValue>
                                            </>
                                        )}
                                        {(props.mode === "2" || props.mode === "3" || props.mode === "4" || props.mode === "5") && ( // part checked
                                            <>
                                                <MainValueContainerValue>
                                                    <MainValueContainerValue>
                                                        {props.values[range]}
                                                    </MainValueContainerValue>
                                                </MainValueContainerValue>
                                            </>
                                        )}
                                    </MainValueContainer>

                    </Container2>
                    <Hr></Hr>
                        <div style={{paddingLeft: 16}}>
                            <Bottom mode={props.mode} range={range}/>
                        </div>
                    </ContainerInside>
                    </span>
                </OverlayTrigger>
            </Container>
        </MainContainer>
    )
}


