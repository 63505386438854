import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import StyledIcon from "../../../components/common/StyledIcon";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar
} from 'recharts';
import Bottom from "./Bottom";
import {Container, Hr, MainContainer, ContainerInside, ContentContainer, Header, Description} from "./common";
import {OverlayTrigger, Tooltip as TooltipRB} from "react-bootstrap";

const ChartContainer = styled.div`
    height: 200px;
    padding-top: 26px;
    padding-bottom: 16px;
    padding-right: 4px;
    margin-top: -40px;
    opacity: 1;
    ${p =>
            p.mode === "1" &&
            css`
                background: linear-gradient(
                    195deg
                    , rgb(102, 187, 106), rgb(67, 160, 71));
                box-shadow: rgb(0 0 0 / 14%) 0 4px 20px 0, rgb(76 175 79 / 40%) 0 7px 10px -5px;
    `}
    ${p =>
            p.mode === "2" &&
            css`
                background: linear-gradient(
                        195deg
                        , rgb(73, 163, 241), rgb(26, 115, 232));
                
                box-shadow: rgb(0 0 0 / 14%) 0 4px 20px 0, rgb(0 187 212 / 40%) 0 7px 10px -5px;
    `}
    ${p =>
            p.mode === "3" &&
            css`
                background: linear-gradient(
                        195deg
                        , rgb(66, 66, 74), rgb(25, 25, 25));
                box-shadow: rgb(0 0 0 / 14%) 0 4px 20px 0, rgb(64 64 64 / 40%) 0 7px 10px -5px;
            `}
    
    color: rgb(52, 71, 103);
    border-radius: 8px;
`;

const ChartCanvas = styled.div`
    display: block;
    box-sizing: border-box;
    height: 200px;
    width: 458px;
`;

const DescriptionContainer = styled.div`
    padding: 24px 8px 8px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`;

const SmallDescription = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`;

const ClockSpan = styled.span`
    margin: 1.2px 4px 0px 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.02857em;
    line-height: 1;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
`;

const ClockSpanInside = styled.span`
    user-select: none;
    width: 1em;
    height: 1em;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    flex-shrink: 0;
    font-size: inherit !important;
`;

const TimeDescription = styled.div`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
    font-weight: 300;
`;

const BottomContainerParagraph = styled.p`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    display: flex;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
`;

const GreenValueSpan = styled.span`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(76, 175, 80);
    font-weight: 700;
    padding-left: 3px;
    padding-right: 3px;
`;

const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: "rgba(1,1,1,0.8)", borderRadius: 5, display: "block", padding: 10, paddingBottom: 5}}>
                <div style={{display: "block"}}>
                    <p style={{color: "#fff"}}>{`${label}`}</p>
                    <span style={{width:10, height: 10, borderRadius: 5, backgroundColor: "#fff", display: "inline-block", marginRight: 5}}/>
                    <p style={{color: "#fff", display: "inline-block"}}>Value: {`${payload[0].value}`}</p>
                </div>
            </div>
        );
    }

    return null;
};
export const CustomTooltipWeek = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const weekHeaderFullDayNameArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return (
            <div style={{ backgroundColor: "rgba(1,1,1,0.8)", borderRadius: 5, display: "block", padding: 10, paddingBottom: 5}}>
                <div style={{display: "block"}}>
                    <p style={{color: "#fff"}}>{`${weekHeaderFullDayNameArray[new Date(payload[0].payload.dateStart.slice(0,10)).getDay()]} ${payload[0].payload.dateStart.slice(0,10)}`}</p>
                    <span style={{width:10, height: 10, borderRadius: 5, backgroundColor: "#fff", display: "inline-block", marginRight: 5}}/>
                    <p style={{color: "#fff", display: "inline-block"}}>Value: {`${payload[0].value}`}</p>
                </div>
            </div>
        );
    }

    return null;
};
export default function ChartContainerComponent (props, {textColor = "black"}) {
    const [width, setWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if(props.data) {
            setIsLoading(false)
        }
    }, [props]);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
        };
        width === 0 && updateWindowDimensions()

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, []);

    const DataFormater = (number) => {
        if(number > 1000000000){
            return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
            return (number/1000000).toString() + 'M';
        }else if(number > 1000){
            return (number/1000).toString() + 'K';
        }else{
            return number.toString();
        }
    }

    const dictionary = {
        week: "This chart displays data for the last seven days, excluding today, based on all the orders you are assigned to. These may not be orders from your entire company.",
        month: "This chart displays data for the current month and previous months, based on all the orders you are assigned to. These may not be orders from your entire company.",
        year: "This chart displays data for the current year and previous years, based on all the orders you are assigned to. These may not be orders from your entire company."
    }

    return (
        <MainContainer>
            <Container>
                {!isLoading && (<ContainerInside onClick={props.clickFunction} style={{cursor: "pointer"}}>
                    <OverlayTrigger overlay={(<TooltipRB id="tooltip">{dictionary[props.range]}</TooltipRB>)} placement="top" delayShow={1500} >
                        <div>
                            <ContentContainer>
                                <ChartContainer mode={props.mode}>
                                    <ResponsiveContainer width="100%" height={164}>
                                        {(props.mode === "1" || props.mode === "3") && <LineChart
                                                width={500}
                                                height={164}
                                                data={props.data[props.range]}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                {/*<CartesianGrid strokeDasharray="3 3" />*/}
                                                <CartesianGrid vertical={false} horizontal={true} strokeWidth="0.2px" storke="#fff"/>
                                                <XAxis dy={(width > 1250 && 10) || (width <= 1250 && 6)} dataKey="name" stroke="rgba(255,255,255,0.8)" strokeWidth="0" style={{fontFamily: 'Roboto, sans-serif', fontSize: 14}} angle={(width < 1250 ? -45 : undefined)} />
                                                <YAxis dx={-10} stroke="rgba(255,255,255,0.8)" strokeWidth="0" style={{fontFamily: 'Roboto, sans-serif', fontSize: 14}} tickFormatter={(value) =>
                                                    new Intl.NumberFormat("en-US", { notation: "compact", compactDisplay: "short" }).format(value)}/>
                                                <Tooltip content={(props.range === "week"? <CustomTooltipWeek /> : <CustomTooltip />)} cursor={{fill: "transparent"}}/>
                                                <Line dataKey="uv" stroke="rgba(255,255,255,0.8)" strokeWidth="4" fill="#fff" />
                                            </LineChart>}

                                        {props.mode === "2" &&
                                            <BarChart width={500} height={164} data={props.data[props.range]}
                                                      margin={{
                                                          top: 10,
                                                          right: 30,
                                                          left: 0,
                                                          bottom: 0,
                                                      }}>
                                                <CartesianGrid vertical={true} horizontal={true} strokeWidth="0.2px" storke="#fff"/>
                                                <XAxis dy={10} dataKey="name" strokeWidth="0" stroke="rgba(255,255,255,0.8)" style={{fontFamily: 'Roboto, sans-serif', fontSize: 14, lineHeight: "200%"}}  />
                                                <YAxis dx={-10} strokeWidth="0" stroke="rgba(255,255,255,0.8)" style={{fontFamily: 'Roboto, sans-serif', fontSize: 14}} tickFormatter={(value) =>
                                                    new Intl.NumberFormat("en-US", { notation: "compact", compactDisplay: "short" }).format(value)} />
                                                <Tooltip content={(props.range === "week"? <CustomTooltipWeek /> : <CustomTooltip />)} cursor={{fill: "transparent"}}/>
                                                <Bar dataKey="uv" fill="rgba(255,255,255,0.7)" stroke="rgba(255,255,255,0.7)" strokeWidth="1" barSize={6} />
                                            </BarChart>
                                        }
                                        </ResponsiveContainer>
                                </ChartContainer>
                                <DescriptionContainer>
                                    <div>
                                        <Header style={{color: props.textColor}}>
                                            {props.title}
                                        </Header>
                                        <Description>
                                            In {props.data && props.data.ordersCount && props.data.ordersCount[props.range]} orders
                                        </Description>
                                    </div>
                                    <Hr />
                                    <Bottom mode={"chart"} range={props.range} text={"last"} rangeCount={props.rangeCount}/>
                                </DescriptionContainer>
                            </ContentContainer>
                        </div>
                    </OverlayTrigger>
                </ContainerInside>)}
            </Container>
        </MainContainer>
    );
}
